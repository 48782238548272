import React from "react";

const Logo: React.FC = () => (
  <svg viewBox="0 0 154 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_7_311" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="1" width="25" height="24">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 1H24.3775V24.3348H0V1Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_7_311)">
      <path fillRule="evenodd" clipRule="evenodd" d="M24.3775 13.1898C24.3775 18.1848 21.3575 22.4723 17.07 24.3348C19.1575 22.6423 20.485 20.0448 20.485 17.1698C20.485 12.0048 16.3075 7.82726 11.145 7.82726C6.01 7.82726 1.8325 12.0048 1.8325 17.1698C1.8325 18.3823 2.06 19.5373 2.51 20.6123C0.93 18.5523 0 15.9823 0 13.1898C0 6.44726 5.445 0.999756 12.19 0.999756C18.905 0.999756 24.3775 6.44726 24.3775 13.1898" fill="#495970"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.4847 17.1692C20.4847 20.0442 19.1572 22.6417 17.0697 24.3342C17.0422 24.3917 16.9847 24.4192 16.9297 24.4192C16.7597 24.5042 16.5897 24.5617 16.3922 24.6167C16.8722 24.2792 17.2972 23.8842 17.6622 23.4617C17.8597 23.2642 18.0297 23.0367 18.1972 22.7817C18.7072 22.0492 19.0747 21.2317 19.2722 20.3542C19.3547 20.0742 19.4122 19.7917 19.4397 19.5117C19.4672 19.1992 19.4972 18.9167 19.4972 18.6067C19.4972 14.5717 16.2222 11.2992 12.1897 11.2992C8.12472 11.2992 4.85222 14.5717 4.85222 18.6067C4.85222 18.9167 4.87972 19.2267 4.90972 19.5117C4.93722 19.7917 4.99472 20.0742 5.07722 20.3542C5.27722 21.2317 5.64222 22.0492 6.15222 22.7817C6.31972 23.0367 6.48972 23.2642 6.68722 23.4617C7.05222 23.8842 7.50472 24.2792 7.95722 24.6167C5.78472 23.8267 3.89222 22.4167 2.51222 20.6117C2.05972 19.5367 1.83472 18.3817 1.83472 17.1692C1.83472 12.0067 6.00972 7.82922 11.1447 7.82922C16.3097 7.82922 20.4847 12.0067 20.4847 17.1692" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.656 25.3797C11.6535 25.3797 9.98852 23.7422 9.98852 21.7097C9.98852 19.6797 11.6535 18.0422 13.656 18.0422C15.686 18.0422 17.3235 19.6797 17.3235 21.7097C17.3235 23.7422 15.686 25.3797 13.656 25.3797M12.1885 11.2997C8.12602 11.2997 4.85352 14.5722 4.85352 18.6072C4.85352 18.9172 4.88102 19.2272 4.90852 19.5097C4.93852 19.7922 4.99352 20.0747 5.07852 20.3547C5.27602 21.2322 5.64102 22.0497 6.15102 22.7822C6.31852 23.0372 6.48852 23.2622 6.68602 23.4597C7.05352 23.8822 7.50602 24.2797 7.95602 24.6172C9.17102 25.4622 10.6085 25.9447 12.1885 25.9447C13.7685 25.9447 15.2085 25.4622 16.3935 24.6172C16.8735 24.2797 17.296 23.8822 17.6635 23.4597C17.861 23.2622 18.031 23.0372 18.1985 22.7822C18.706 22.0497 19.0735 21.2322 19.271 20.3547C19.356 20.0747 19.411 19.7922 19.4385 19.5097C19.4685 19.2272 19.496 18.9172 19.496 18.6072C19.496 14.5722 16.2235 11.2997 12.1885 11.2997" fill="#495970"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M30.9003 22.4433V0.850769H35.3828V9.08327H43.7753V0.850769H48.2578V22.4433H43.7753V12.8058H35.3828V22.4433H30.9003Z" fill="#495970"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M51.9875 22.4445H56.22V6.47698H51.9875V22.4445ZM51.9875 4.57198H56.22V0.719482H51.9875V4.57198Z" fill="#495970"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M69.4242 18.1073C70.0392 17.2273 70.3467 16.0898 70.3467 14.6948C70.3467 12.7423 69.8542 11.3448 68.8667 10.5048C68.2617 9.99726 67.5592 9.74226 66.7592 9.74226C65.5367 9.74226 64.6417 10.2048 64.0692 11.1273C63.4992 12.0498 63.2142 13.1948 63.2142 14.5623C63.2142 16.0373 63.5042 17.2173 64.0842 18.0998C64.6667 18.9848 65.5467 19.4248 66.7292 19.4248C67.9117 19.4248 68.8092 18.9873 69.4242 18.1073M68.3992 6.73226C69.1517 7.15726 69.7617 7.74476 70.2292 8.49726V0.879761H74.4642V22.4423H70.4067V20.2323C69.8092 21.1798 69.1317 21.8673 68.3692 22.2973C67.6067 22.7273 66.6617 22.9423 65.5267 22.9423C63.6617 22.9423 62.0917 22.1873 60.8192 20.6773C59.5442 19.1698 58.9067 17.2323 58.9067 14.8698C58.9067 12.1448 59.5342 10.0023 60.7892 8.43976C62.0442 6.87726 63.7217 6.09476 65.8217 6.09476C66.7867 6.09476 67.6467 6.30726 68.3992 6.73226" fill="#495970"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M82.0867 10.407C81.4967 11.0145 81.1267 11.837 80.9742 12.877H88.1667C88.0917 11.7695 87.7192 10.9295 87.0542 10.3545C86.3892 9.782 85.5642 9.4945 84.5792 9.4945C83.5067 9.4945 82.6767 9.7995 82.0867 10.407M88.3317 6.7995C89.4467 7.2995 90.3667 8.0845 91.0917 9.162C91.7442 10.112 92.1692 11.2095 92.3642 12.462C92.4767 13.1945 92.5217 14.252 92.5017 15.632H80.8717C80.9367 17.232 81.4942 18.357 82.5417 19.002C83.1792 19.402 83.9467 19.602 84.8442 19.602C85.7942 19.602 86.5667 19.357 87.1617 18.8695C87.4867 18.6045 87.7717 18.2395 88.0217 17.7695H92.2842C92.1717 18.717 91.6542 19.6795 90.7342 20.657C89.3042 22.2095 87.3017 22.9845 84.7292 22.9845C82.6042 22.9845 80.7292 22.332 79.1042 21.0195C77.4792 19.712 76.6692 17.5795 76.6692 14.627C76.6692 11.862 77.4017 9.7395 78.8667 8.267C80.3342 6.7895 82.2367 6.052 84.5767 6.052C85.9667 6.052 87.2192 6.2995 88.3317 6.7995" fill="#495970"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M94.37 22.442H98.545V0.849487H94.37V22.442Z" fill="#495970"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M112.262 18.1828C112.917 17.3128 113.245 16.0803 113.245 14.4828C113.245 12.8828 112.917 11.6528 112.262 10.7878C111.607 9.92527 110.67 9.49277 109.45 9.49277C108.23 9.49277 107.29 9.92527 106.63 10.7878C105.97 11.6528 105.642 12.8828 105.642 14.4828C105.642 16.0803 105.97 17.3128 106.63 18.1828C107.29 19.0503 108.23 19.4853 109.45 19.4853C110.67 19.4853 111.607 19.0503 112.262 18.1828M115.602 20.4903C114.255 22.1528 112.21 22.9853 109.465 22.9853C106.72 22.9853 104.675 22.1528 103.327 20.4903C101.98 18.8278 101.305 16.8228 101.305 14.4828C101.305 12.1803 101.98 10.1853 103.327 8.49527C104.675 6.80777 106.72 5.96277 109.465 5.96277C112.21 5.96277 114.255 6.80777 115.602 8.49527C116.95 10.1853 117.625 12.1803 117.625 14.4828C117.625 16.8228 116.95 18.8278 115.602 20.4903" fill="#495970"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M130.258 18.1828C130.913 17.3128 131.241 16.0803 131.241 14.4828C131.241 12.8828 130.913 11.6528 130.258 10.7878C129.603 9.92527 128.666 9.49277 127.446 9.49277C126.226 9.49277 125.286 9.92527 124.626 10.7878C123.966 11.6528 123.638 12.8828 123.638 14.4828C123.638 16.0803 123.966 17.3128 124.626 18.1828C125.286 19.0503 126.226 19.4853 127.446 19.4853C128.666 19.4853 129.603 19.0503 130.258 18.1828M133.598 20.4903C132.251 22.1528 130.206 22.9853 127.461 22.9853C124.716 22.9853 122.671 22.1528 121.323 20.4903C119.976 18.8278 119.301 16.8228 119.301 14.4828C119.301 12.1803 119.976 10.1853 121.323 8.49527C122.671 6.80777 124.716 5.96277 127.461 5.96277C130.206 5.96277 132.251 6.80777 133.598 8.49527C134.946 10.1853 135.621 12.1803 135.621 14.4828C135.621 16.8228 134.946 18.8278 133.598 20.4903" fill="#495970"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M148.501 11.1932C147.934 10.2457 147.014 9.77323 145.741 9.77323C144.211 9.77323 143.161 10.4907 142.589 11.9257C142.294 12.6882 142.146 13.6532 142.146 14.8257C142.146 16.6807 142.644 17.9857 143.639 18.7382C144.231 19.1757 144.931 19.3957 145.741 19.3957C146.914 19.3957 147.811 18.9482 148.426 18.0482C149.044 17.1507 149.351 15.9532 149.351 14.4607C149.351 13.2282 149.069 12.1407 148.501 11.1932M151.706 8.19073C153.009 9.56823 153.659 11.5882 153.659 14.2557C153.659 17.0682 153.024 19.2107 151.749 20.6857C150.476 22.1607 148.836 22.8982 146.834 22.8982C145.554 22.8982 144.494 22.5807 143.649 21.9457C143.186 21.5932 142.734 21.0807 142.291 20.4082V28.7282H138.161V6.47573H142.159V8.83573C142.611 8.14073 143.094 7.59573 143.606 7.19323C144.539 6.48073 145.649 6.12573 146.936 6.12573C148.814 6.12573 150.404 6.81323 151.706 8.19073" fill="#495970"/>
  </svg>

);

export default Logo;
