import * as React from 'react';
import { Helmet } from 'react-helmet';

const Seo: React.FC = () => {
  const title =
    'Hideloop';
  return (
    <Helmet
      htmlAttributes={{
        lang: 'es',
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
      ]}
    />
  );
};

export default Seo;
