import React from "react"
import Logo from "../components/Logo";
import styled from "styled-components";
import { Normalize } from 'styled-normalize'

import Seo from "../components/Seo";

const IndexPage: React.FC = () => {
  return (
    <>
      <Seo />
      <Normalize />
      <StyledMain>
        <Container>
          <Logo />
          <a href="mailto:contact@hideloop.com">contact@hideloop.com</a>
        </Container>
      </StyledMain>
    </>
  )
}

const StyledMain = styled.main`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
`;

const Container  = styled.div`
  max-width: 500px;
  width: 100%;
  text-align: center;
  
  a {
    display: block;
    font-family: Helvetica, sans-serif;
    color: #495970;
    font-weight: bold;
    text-decoration: none;
    margin-top: 10px;
    &:hover {
      text-decoration: underline;
    }
  }
`



export default IndexPage
